import React from 'react';
import { Section } from 'react-komponents';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageTitle } from '../ImageTitle/imageTitle.component';

export const TrustedSection = () => {
  const data = useStaticQuery(graphql`
    {
      eurosport: file(relativePath: { eq: "eurosport.png" }) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, layout: FULL_WIDTH)
        }
      }
      meetic: file(relativePath: { eq: "meetic.png" }) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, layout: FULL_WIDTH)
        }
      }
      panda: file(relativePath: { eq: "panda.png" }) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, layout: FULL_WIDTH)
        }
      }
      discovery: file(relativePath: { eq: "discovery.png" }) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Section beta>
      <ImageTitle
        images={[data.eurosport, data.meetic, data.panda, data.discovery]}
        title="Clients."
      />
    </Section>
  );
};
