import React from "react"
import { Section } from "react-komponents"
import { Paragraphs } from "../Paragraphs/paragraphs.component"

export const PresentationSection = () => (
  <Section alpha>
    <Paragraphs
      sections={[
        {
          title: "APPLICATIONS WEB",
          content:
            "Après plus de 10 ans d'expérience dans la création de site internet et application web, j'accompagne aujourd'hui les grands groupes et startups dans la réalisation de leur produit final. Cela peut être un site vitrine, une PWA pour un site de dating en ligne tel que Meetic ou encore l'architecture logicielle pour le grand média européen Eurosport.",
          footer: "",
        },
        {
          title: "ARCHITECTURE LOGICIELLE",
          content:
            "Les années et l'expérience, m'ont permis d'améliorer la manière dont j'aborde l'architecture logicielle de sorte à ce qu'aujourd'hui je puisse effectuer les choix les plus adaptés à la création de vos applications Web et Mobile. \n",
          footer: "",
        },
        {
          title: "APPLICATIONS MOBILES",
          content:
            "Fort de mon expertise en développement mobile, j'accompagne startups et grandes compagnies dans la réalisation de leur(s) projet(s). Je sais concevoir tout type d'application mobile. Par le passé, j'ai pu mener à bien la création d'une application de VOD pour une chaîne de télévision européenne, un chat pour médecins ou encore une application de dating en ligne.",
          footer: "",
        },
      ]}
    />
  </Section>
)
