import React from 'react';
import { navigate } from 'gatsby-link';
import { Section, SectionTitle } from 'react-komponents';
import { ImagesGallery } from '../ImagesGallery/imagesGallery.component';
import { graphql, useStaticQuery } from 'gatsby';
import { formatArticleSection } from '../../utils/formatArticleSection';

export const BlogSection = () => {
  const data = useStaticQuery(graphql`
    {
      latestPosts: allMarkdownRemark(
        limit: 6
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { template: { eq: "post" } } }
      ) {
        edges {
          node {
            excerpt
            timeToRead
            fields {
              slug
            }
            frontmatter {
              title
              categories
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 500
                    layout: CONSTRAINED
                  )
                }
              }
              date
              template
            }
          }
        }
      }
    }
  `);

  const postsSection = formatArticleSection(data.latestPosts, 'fr-FR');

  return (
    <Section beta>
      <SectionTitle>{'Blog.'}</SectionTitle>
      <ImagesGallery onLinkClick={navigate} cards={postsSection} />
    </Section>
  );
};
