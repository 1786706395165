import React from "react"
import styled from "styled-components"
import { MEDIA_TABLET, Paragraph } from "react-komponents"

export const Paragraphs = ({ sections }) => (
  <Container>
    {sections.map(({ title, content, footer }) => (
      <Separator>
        <Paragraph title={title} content={content} footer={footer} />
      </Separator>
    ))}
  </Container>
)

const Separator = styled.div`
  margin-right: ${({ theme }) => theme.spacing.large};
  margin-left: ${({ theme }) => theme.spacing.large};
  display: flex;
  flex: 1;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: ${MEDIA_TABLET}px) {
    flex-direction: column;
  }
  flex-wrap: wrap;
`
