import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MEDIA_TABLET, SectionTitle } from 'react-komponents';

export const ImageTitle = ({ images, title }) => (
  <Container>
    <SeparatorFirst>
      <SectionTitle>{title}</SectionTitle>
    </SeparatorFirst>
    {images.map(image => (
      <SeparatorFirst>
        <Image
          imgStyle={{ objectFit: 'contain' }}
          fadeIn={true}
          objectFit="cover"
          image={image.childImageSharp.gatsbyImageData}
        />
      </SeparatorFirst>
    ))}
  </Container>
);

const Image = styled(GatsbyImage)`
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  margin: ${({ theme }) => theme.spacing.medium}
    ${({ theme }) => theme.spacing.medium}
    ${({ theme }) => theme.spacing.medium}
    ${({ theme }) => theme.spacing.medium};
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: ${MEDIA_TABLET}px) {
    flex-direction: column;
  }
  @media (min-width: ${MEDIA_TABLET}px) {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const SeparatorFirst = styled.div`
  display: flex;
  flex: 1;
`;
