import React from 'react';

import SEO from '../components/seo';
import { HomeLayout } from '../components/Layouts/layout';
import { TrustedSection } from '../components/Sections/trusted.component';
import { PresentationSection } from '../components/Sections/presentation.component';
import { PortfolioSection } from '../components/Sections/portfolio.component';
import { BlogSection } from '../components/Sections/blog.component';

class HomePage extends React.Component {
  render() {
    return (
      <HomeLayout location={this.props.location}>
        <SEO title="Julien Karst | Tech lead mobile & web developer | Paris | Nancy | France" />
        <PresentationSection />
        <TrustedSection />
        <PortfolioSection />
        <BlogSection />
      </HomeLayout>
    );
  }
}

export default HomePage;
