import React from 'react';
import { Section, SectionTitle } from 'react-komponents';
import { navigate } from 'gatsby-link';
import { graphql, useStaticQuery } from 'gatsby';
import { ImagesGallery } from '../ImagesGallery/imagesGallery.component';
import { formatArticleSection } from '../../utils/formatArticleSection';

export const PortfolioSection = () => {
  const data = useStaticQuery(graphql`
    {
      latestProjects: allMarkdownRemark(
        limit: 6
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { template: { eq: "project" } } }
      ) {
        edges {
          node {
            excerpt
            timeToRead
            fields {
              slug
            }
            frontmatter {
              title
              categories
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 500
                    layout: CONSTRAINED
                  )
                }
              }
              date
              template
            }
          }
        }
      }
    }
  `);

  const projectsSection = formatArticleSection(data.latestProjects, 'fr-FR');
  return (
    <>
      <Section charlie>
        <SectionTitle>Portfolio.</SectionTitle>
        <ImagesGallery onLinkClick={navigate} cards={projectsSection} />
      </Section>
    </>
  );
};
